<template>
    <div :class="['modal', { open: active }]">
        <div class="modal__content">
            <ModalClose :close-event="closeEvent" modal-class="modal__close"></ModalClose>
            <div class="modal__items">
                <img
                    :src="logoUrl"
                    alt="Community Logo"
                    height="65"
                    width="65"
                >
                <h4>{{ title }}</h4>
                <p v-html="message"></p>
                <button
                    v-if="cta !== false"
                    class="button"
                    @click="joinCommunity"
                >
                    {{ buttonText }}
                </button>
                <button
                    v-if="cta === false && emit && buttonText != null"
                    class="button"
                    @click="emitEvent"
                >
                    {{ buttonText }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ModalClose from "./ModalClose.vue";

export default {
    components: {ModalClose},
    data() {
        return {
            active: false,
            closeEvent: 'bus-modal-close-event',
            communityId:
                typeof this.$root.community != 'undefined'
                    ? this.$root.community.id
                    : this.$root.placeholderCommunityId,
            communityThumbnail:
                typeof this.$root.community != 'undefined'
                    ? this.$root.community.thumbnail
                    : null,
            communityName:
                typeof this.$root.community != 'undefined'
                    ? this.$root.community.name
                    : null,
            logoUrl:
                typeof this.$root.community != 'undefined'
                    ? this.$simpleStore.cloudinaryUrl(
                          106,
                          106,
                          'img/communities/' +
                              this.$root.community.id +
                              '/' +
                              this.$root.community.image,
                          this.$root
                      )
                    : null,
            title: 'Join ',
            message: null,
            cta: '/api/v1/communities/join/',
            buttonText: 'Join this community',
            emit: null,
            closeCta: null,
        };
    },
    created() {
        this.cta += this.communityId;
        this.title += this.communityName;
        this.$root.$on(this.closeEvent, this.close);
        this.$root.$on('bus-show-community-modal', (data) => {
            this.message = data.message;
            if (typeof data.cta != 'undefined') {
                this.cta = data.cta;
            }
            if (typeof data.buttonText != 'undefined') {
                this.buttonText = data.buttonText;
            }
            if (typeof data.title != 'undefined') {
                this.title = data.title;
            }
            if (typeof data.communityId != 'undefined') {
                this.communityId = data.communityId;
            }
            if (typeof data.communityName != 'undefined') {
                this.communityName = data.communityName;
            }
            if (typeof data.communityThumbnail != 'undefined') {
                this.communityThumbnail = data.communityThumbnail;
            }
            if (
                typeof data.communityId != 'undefined' &&
                typeof data.communityThumbnail != 'undefined'
            ) {
                this.logoUrl = this.$simpleStore.cloudinaryUrl(
                    106,
                    106,
                    'img/communities/' +
                        this.communityId +
                        '/' +
                        this.communityThumbnail,
                    this.$root
                );
            }
            if (typeof data.emit != 'undefined') {
                this.emit = data.emit;
            }
            this.active = true;
            console.log('modal data', data);
            console.log(this);
            console.log('this.$refs.modalClose: ', this.$refs.modalClose);
        });
    },
    mounted() {},
    methods: {
        close() {
            this.active = false;
            if (this.emit === 'bus-close-invite-panel') {
                this.$root.$emit(this.emit);
            }
            if (this.closeCta != null) {
                window.location = this.closeCta;
            }
        },
        emitEvent() {
            this.$root.$emit(this.emit);
            this.close();
        },
        joinCommunity() {
            this.$simpleStore.join(this, this.communityId);
            /*this.$http.get(this.cta).then(
                (response) => {
                    let data = response.data;
                    console.log('data joinCommunity in modal');
                    console.log(data);
                    if (data.error === true) {
                        this.title = 'Error Joining';
                        this.message = data.message;
                    } else {
                        this.$root.is_member = 1;
                        this.title = 'Successfully Joined';
                        this.message = 'You have successfully joined the ' + this.communityName + ' community\n';
                        if (typeof data.joined != 'undefined' && data.joined) {
                            this.message += 'and liked this post';
                        }
                        this.cta = false;
                        // TODO: update cached feed and explore sections after becoming a member - for now just delete and redirect
                        // remove feed and explore cache to they now refactor correctly on reload
                        sessionStorage.removeItem('feed');
                        sessionStorage.removeItem('explore');
                        sessionStorage.removeItem('c' + this.communityId);
                        //this.closeCta = window.location.href;
                    }
                },
                (response) => {
                    this.title = 'Error Joining';
                    if (response.status === 403) {
                        this.message = 'You do not have permission to complete this request.';
                    } else {
                        this.message = 'An unknown error occurred when joining.';
                    }
                    console.log('Errored:');
                    console.log(response);
                }
            );*/
        },
    },
};
</script>
