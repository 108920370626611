var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["modal", { open: _vm.active }] }, [
    _c(
      "div",
      { staticClass: "modal__content" },
      [
        _c("ModalClose", {
          attrs: {
            "close-event": _vm.closeEvent,
            "modal-class": "modal__close",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "modal__items" }, [
          _c("img", {
            attrs: {
              src: _vm.logoUrl,
              alt: "Community Logo",
              height: "65",
              width: "65",
            },
          }),
          _vm._v(" "),
          _c("h4", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("p", { domProps: { innerHTML: _vm._s(_vm.message) } }),
          _vm._v(" "),
          _vm.cta !== false
            ? _c(
                "button",
                { staticClass: "button", on: { click: _vm.joinCommunity } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.buttonText) +
                      "\n            "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.cta === false && _vm.emit && _vm.buttonText != null
            ? _c(
                "button",
                { staticClass: "button", on: { click: _vm.emitEvent } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.buttonText) +
                      "\n            "
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }